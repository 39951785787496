// hooks
import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// components
import SuspensedView from 'components/common/SuspensedView';
import LayoutWrapper from 'components/layout/LayoutWrapper';
import ProtectedRoute from 'middleware/ProtectedRoute';

// config
import { routes } from 'config/routes';

// pages
const ProductsPage = lazy(() => import('components/pages/products'));
const CategoriesPage = lazy(() => import('components/pages/categories'));
const BrandsPage = lazy(() => import('components/pages/brands'));
const ProjectsPage = lazy(() => import('components/pages/projects'));

const ProductsPageElement = () => {
  return (
    <SuspensedView>
      <ProtectedRoute>
        <ProductsPage />
      </ProtectedRoute>
    </SuspensedView>
  );
};

const CategoriesPageElement = () => {
  return (
    <SuspensedView>
      <ProtectedRoute>
        <CategoriesPage />
      </ProtectedRoute>
    </SuspensedView>
  );
};

const BrandPageElement = () => {
  return (
    <SuspensedView>
      <ProtectedRoute>
        <BrandsPage />
      </ProtectedRoute>
    </SuspensedView>
  );
};

const ProjectsPageElement = () => {
  return (
    <SuspensedView>
      <ProtectedRoute>
        <ProjectsPage />
      </ProtectedRoute>
    </SuspensedView>
  );
};

const PrivateRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<LayoutWrapper />}>
          <Route
            path={routes.children.login}
            element={<Navigate to={routes.children.products} />}
          />
          <Route
            path={routes.children.products}
            element={<ProductsPageElement />}
          />
          <Route
            path={routes.children.categories}
            element={<CategoriesPageElement />}
          />
          <Route
            path={routes.children.projects}
            element={<ProjectsPageElement />}
          />
          <Route path={routes.children.brands} element={<BrandPageElement />} />
          <Route path="*" element={<Navigate to="/error" />} />
        </Route>
      </Routes>
    </>
  );
};

export default PrivateRoutes;
