// hooks
import { lazy } from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';

// components
import App from 'App';
import ErrorsPage from './ErrorsPage';
import SuspensedView from 'components/common/SuspensedView';
import PrivateRoutes from './PrivateRoutes';

// config
import { routes } from '../../config/routes';

// pages
const LoginPage = lazy(() => import('components/pages/login'));

// elements
const LoginPageElement = () => {
  return (
    <SuspensedView>
      <LoginPage />
    </SuspensedView>
  );
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="/*" element={<PrivateRoutes />} />
          <Route index element={<Navigate to={routes.children.products} />} />
          <Route path={routes.children.login} element={<LoginPageElement />} />
          <Route path="*" element={<Navigate to={routes.children.login} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
