// components
import SidebarItem from './components/SidebarItem';

// config
import { sidebarItems } from 'config/sidebarItems';

const Sidebar = () => {
  return (
    <>
      <aside className="bg-[#262626] hidden lg:block p-6 h-[600px] max-w-[290px] rounded-2xl mr-4 w-1/3">
        {sidebarItems.map((item, index) => (
          <SidebarItem key={index} name={item.name} route={item.route} />
        ))}
      </aside>
    </>
  );
};

export default Sidebar;
