/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, ReactNode, useMemo } from 'react';

// types
import { AuthContextType } from './types';

const AuthContext = createContext<AuthContextType>(null!);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const signOut = (callback: () => void) => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('expiration');
    callback();
  };

  const getTokenDuration = () => {
    const storedExpirationDate = sessionStorage.getItem('expiration');
    const expirationDate = new Date(storedExpirationDate!);

    const now = new Date();
    const duration = expirationDate.getTime() - now.getTime();
    return duration;
  };

  const getAuthToken = () => {
    const token = sessionStorage.getItem('token');

    if (!token) {
      return null;
    }

    const tokenDuration = getTokenDuration();

    if (tokenDuration < 0) {
      return 'EXPIRED';
    }

    return token;
  };

  const value = useMemo(
    () => ({ signOut, getAuthToken, getTokenDuration }),
    [signOut]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthProvider, AuthContext };
