// hooks
import { Outlet } from 'react-router-dom';

// components
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';

const LayoutWrapper = () => {
  return (
    <div className="flex flex-col items-center w-full justify-between min-h-screen">
      <Header />
      <div className="flex mt-[100px] max-width w-full">
        <Sidebar />
        <main className="w-full pb-10">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default LayoutWrapper;
