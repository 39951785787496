// hooks
import { useState } from 'react';

// components
import BurgerMenu from './components/burgerMenu';
import LogoutButton from './components/LogoutButton';

// assets
import Logo from '../../../assets/img/logo-ka.png';

const Header = () => {
  const [isBurgerOpened, setIsBurgerOpened] = useState<boolean>(false);
  const genericHamburgerLine = `h-0.5 w-6 mb-1 rounded-full ${
    isBurgerOpened ? 'bg-white' : 'bg-blue'
  }  transition ease transform duration-300`;

  return (
    <div className="flex h-[70px] fixed rounded-b-2xl z-[100] bg-white justify-center items-center w-full">
      <div className="flex w-full justify-between items-center max-width h-full">
        <div className="flex h-full py-4 w-[200px]">
          <img className="object-contain w-full h-full" src={Logo} alt="logo" />
        </div>
        <LogoutButton className="hidden lg:block" />
      </div>
      <div className="absolute right-4 lg:hidden z-[51]">
        <button
          type="button"
          className="flex flex-col h-12 w-12 rounded justify-center items-center group"
          onClick={() => setIsBurgerOpened(!isBurgerOpened)}
        >
          <div
            className={`${genericHamburgerLine} ${
              isBurgerOpened ? 'rotate-45 translate-y-3' : ''
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isBurgerOpened ? 'opacity-0' : ''
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isBurgerOpened ? '-rotate-45 -translate-y-0.1' : ''
            }`}
          />
        </button>
      </div>
      <BurgerMenu
        isBurgerOpened={isBurgerOpened}
        setIsBurgerOpened={setIsBurgerOpened}
      />
    </div>
  );
};

export default Header;
