// utils
import classNames from 'classnames';
import { overrideTailwindClasses } from 'tailwind-override';

// types
import type { HTMLProps } from 'react';

type AdditionalProps = {
  type?: 'button' | 'submit' | 'reset' | undefined;
  large?: boolean;
  small?: boolean;
  white?: boolean;
  danger?: boolean;
  info?: boolean;
  loading?: boolean;
  className?: string;
  icon?: any;
};
type ButtonProps = HTMLProps<HTMLButtonElement> & AdditionalProps;

const Button = ({
  type = 'button',
  large,
  small,
  white,
  danger,
  info,
  disabled,
  children,
  loading,
  className,
  icon,
  ...props
}: ButtonProps) => {
  const Icon = icon;
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      disabled={disabled || loading}
      className={overrideTailwindClasses(
        classNames(
          'relative',
          'flex',
          'items-center',
          'justify-center',
          'text-sm',
          'p-4',
          'px-8',
          'h-10',
          'w-full',
          'bg-lightRed',
          'outline-none',
          'focus:outline-none',
          'rounded-lg',
          'text-white',
          'border-none',
          'justify-center',
          className,
          {
            'p-5 h-16': large,
            'p-2 h-8': small,
            'p-2 h-10': !small,
            'bg-white  text-black': white,
            'bg-red  text-white': danger,
            'bg-blue  text-white': info,
            'cursor-not-allowed opacity-75': disabled || loading,
            'hover:opacity-70': !loading && !disabled
          }
        )
      )}
      {...props}
    >
      {loading ? (
        <span className="text-sm">Loading...</span>
      ) : (
        <>
          <div className="flex items-center">
            {icon && <Icon className="absolute top-3 right-3" />}
            {children}
          </div>
        </>
      )}
    </button>
  );
};

export default Button;
