// hooks
import { Outlet } from 'react-router';

// styles
import './App.scss';

const App = () => {
  return (
    <div className="App">
      <Outlet />
    </div>
  );
};

export default App;
