export const routes = {
  basePath: '/',
  children: {
    login: '/login',
    dashboard: '/dashboard',
    products: '/products',
    categories: '/categories',
    brands: '/brands',
    projects: '/projects'
  }
};
