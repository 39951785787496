// hooks
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';

type SidebarItemProps = {
  name: string;
  route: string;
};

const SidebarItem = ({ name, route }: SidebarItemProps) => {
  const { pathname } = useLocation();

  const routeCurrentPagePart = pathname.split('/')[1] ?? '';

  return (
    <>
      <div
        className={classNames(
          'flex h-[50px] rounded-[10px] px-[10px] py-4 mb-4  items-center',
          {
            'bg-blue': routeCurrentPagePart === route.substring(1)
          }
        )}
      >
        <Link to={route}>
          <span>{name}</span>
        </Link>
      </div>
    </>
  );
};

export default SidebarItem;
