// config
import { routes } from './routes';

export const sidebarItems = [
  {
    name: 'პროდუქტები',
    route: routes.children.products
  },
  {
    name: 'კატეგორიები',
    route: routes.children.categories
  },
  {
    name: 'ბრენდები ',
    route: routes.children.brands
  },
  {
    name: 'პროექტები',
    route: routes.children.projects
  }
];
