// hooks
import { useLayoutEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// custom hooks
import { useAuth } from 'hooks/useAuth';

// config
import { routes } from 'config/routes';

type Props = {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
};

enum AuthStates {
  LOADING,
  YES,
  NO
}

const ProtectedRoute = ({ children }: Props) => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<AuthStates>(
    AuthStates.LOADING
  );

  const { getAuthToken, getTokenDuration, signOut } = useAuth();
  const location = useLocation();
  const token = getAuthToken();

  useLayoutEffect(() => {
    const tokenDuration = getTokenDuration();

    if (!token) {
      setIsAuthenticated(AuthStates.NO);
    } else {
      setIsAuthenticated(AuthStates.YES);
    }

    setTimeout(() => {
      signOut(() => {
        navigate(routes.children.login);
        setIsAuthenticated(AuthStates.NO);
      });
    }, tokenDuration);
  }, [token]);

  return (
    <>
      {isAuthenticated === AuthStates.LOADING && <span>Loading...</span>}
      {isAuthenticated === AuthStates.NO && (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
      {isAuthenticated === AuthStates.YES && children}
    </>
  );
};

export default ProtectedRoute;
