// hooks
import { useNavigate } from 'react-router-dom';

// components
import Button from 'components/common/button/Button';

// assets
import { LogoutIcon } from 'assets/icons';

// custom hooks
import { useAuth } from 'hooks/useAuth';
import classNames from 'classnames';

type Props = {
  className: string;
};

const LogoutButton = ({ className }: Props) => {
  const navigate = useNavigate();

  // custom hooks
  const { signOut } = useAuth();

  return (
    <>
      <Button
        className={classNames('w-[130px]', className)}
        icon={LogoutIcon}
        onClick={() => signOut(() => navigate('/'))}
      >
        გასვლა
      </Button>
    </>
  );
};

export default LogoutButton;
