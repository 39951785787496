/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
// hooks
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

// components
import { sidebarItems } from 'config/sidebarItems';
import LogoutButton from './LogoutButton';

type Props = {
  isBurgerOpened: boolean;
  setIsBurgerOpened: (e: boolean) => void;
};

const BurgerMenu = ({ isBurgerOpened, setIsBurgerOpened }: Props) => {
  const handleClose = () => setIsBurgerOpened(false);

  return (
    <div
      className={`fixed bg-[#262626] w-full top-0 transition-all min-h-screen z-[50] duration-500 ease-in-out ${
        isBurgerOpened ? 'left-0' : 'left-full'
      }`}
    >
      <ul className="flex mt-[135px] flex-col text-center whitespace-nowrap text-[18px]">
        {sidebarItems.map((item: any, index: number) => (
          <Fragment key={index}>
            <li onClick={handleClose}>
              <Link to={item.route}>
                <p className="mb-[48px] transition-colors cursor-pointer'text-white">
                  {item.name}
                </p>
              </Link>
            </li>
          </Fragment>
        ))}
      </ul>
      <div className="flex w-full justify-center">
        <LogoutButton className="block" />
      </div>
    </div>
  );
};

export default BurgerMenu;
